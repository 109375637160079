import translate from "../config/translations.js"

/**
 * Changes the format of a date so that it is displayed in
 * the format that is required for the given locale
 */
export function formatDate(date, currentLocale) {
  const months = translate('months')
  date = new Date(date)

  /**
   * If the current locale is 'mn-MNG', display the date in <year>-<month>-<day>
   * format, else display the date in <month> <day> format
   */
  date = currentLocale === 'mn-MNG'
    ? `${date.getFullYear()}-${("0"
      + date.getMonth()).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
    : `${months[date.getMonth()]} ${date.getDate()}`
  return date
}