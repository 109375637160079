/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useContext, useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import ReactPaginate from 'react-paginate';
import '../styles/fonts.css'
import '../styles/pagination.scss'

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from "../components/layout"
import Container from "../components/container"
import Heading from '../components/heading'
import ArticleCard from "../components/category/article-card";

const CategoryPage = ({ currentLocale, setCurrentLocale }) => {
  let { categoryId } = useParams()

  const GET_ARTICLES = gql`
    query GetArticles($pageLimit: Int!, $pageOffset: Int!, $currentLocale: String! $categoryId: String!) {
      articleCollection(
        limit: $pageLimit,
        skip: $pageOffset,
        locale: $currentLocale,
        order: sys_publishedAt_DESC,
        where:{ AND:[
          { category: {
              sys: {
                id: $categoryId
              }
            }
          }
        ]}
      ) {
        total
        items {
          title
          category {
            name
          }
          sys {
            id
            firstPublishedAt
            publishedAt
          }
        }
      }
    }
  `

  const currentSiteAccess = useContext(SiteAccessContext)

  const [articles, setArticles] = useState(undefined)
  const [pageOffset, setPageOffset] = useState(0)

  const pageLimit = 10

  const { data, loading, error, fetchMore } = useQuery(GET_ARTICLES, {
    variables: { pageOffset, pageLimit, currentLocale, categoryId },
    fetchPolicy: 'cache-and-network'
  })
  
  useEffect(() => {
    if (loading === false && data) {
      setArticles(data.articleCollection.items)
    }
    if (error) console.error(error)
  }, [data, loading, error])

  const pageCount = data ? Math.floor((data.articleCollection.total + pageLimit - 1) / pageLimit) : ''
  
  const handlePageChange = (page) => {
    setPageOffset(page.selected * pageLimit)
    fetchMore({
      variables: {
        pageOffset
      }
    })
  }
  
  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
      >
        <Container>
          <Heading pageTitle={articles ? articles[0].category.name : ''} />
          {articles ? articles.map((article, key) =>
            <ArticleCard article={article} key={key} currentLocale={currentLocale}/>
          ) : ``}
          {
            pageCount > 1 ?
            (
              <ReactPaginate
                pageCount={pageCount}
                pageRangeDisplayed={1}
                marginPagesDisplayed={2}
                previousLabel={'<'}
                nextLabel={'>'}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                pageClassName={'pagination__pagination-button'}
                previousClassName={'pagination__pagination-button--previous'}
                nextClassName={'pagination__pagination-button--next'}
                breakClassName={'pagination__pagination-link--break'}
                pageLinkClassName={'pagination__pagination-link'}
                previousLinkClassName={'pagination__pagination-link'}
                nextLinkClassName={'pagination__pagination-link'}
                activeLinkClassName={'pagination__pagination-link'}
                activeClassName={'pagination__pagination-button--active'}
              />
            ) : ''
          }          
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default CategoryPage
