/** @jsx jsx */
import { jsx, Flex, Text, Image } from 'theme-ui'
import PropTypes from "prop-types"
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { formatDate } from "../../utils/date"

import IframeContainer from "./iframe-container"

import locationIcon from "../../images/user-icon.svg"
import dateIcon from "../../images/calendar-icon.svg"

const articleBody = {
  marginTop: '24px',
  fontSize: ['null', '19px'],
  'p': {
    color: 'secondary'
  },
  'ul, ol, li': {
    padding: 0,
    color: 'secondary',
    'p': {
      display: 'inline'
    },
  },
  'ul, ol': {
    marginTop: '12px',
    color: 'secondary',
    paddingLeft: '20px',
    'li': {
      paddingLeft: '24px'
    }
  },
  'li span': {
    position: 'relative',
    left: '10px'
  },
  'blockquote': {
    margin: '24px 0px',
    paddingLeft: '24px',
    borderLeft: '4px solid',
    borderColor: 'dullerBlue'
  },
  'h1': {
    fontSize: ['24px', '28px']
  },
  'h2': {
    fontSize: ['21px', '24px']
  },
  'h3': {
    fontSize: ['18px', '21px']
  },
  'h4': {
    fontSize: ['16px', '18px']
  },
  'img': {
    margin: '0',
    height: '230px',
    width: '100%',
    objectFit: 'cover'
  },
  'a': {
    color: 'secondary'
  },
  'a:hover': {
    textDecoration: 'none'
  },
  'figure': {
    margin: '0'
  },
  'figcaption': {
    margin: '15px',
    color: '#A0C4E0',
    textAlign: 'center',
    fontSize: '16px',
  },
  'img.inline-image': {
    height: 'auto'
  }
}

const NewsArticle = (props) => {
  const isAboutPage = window.location.href.includes("about")
  var embeddedAssetCount = 0;
  const options = (block) => {
    return {
      renderNode: {
        [INLINES.HYPERLINK]: (node) => {
          if((node.data.uri).includes("player.vimeo.com/video")){
            return <IframeContainer><iframe title="embedded vimeo video" src={node.data.uri} frameBorder="0" allowFullScreen></iframe></IframeContainer>
          } else if((node.data.uri).includes("youtube.com/embed")) {
            return <IframeContainer><iframe title="embedded youtube video" src={node.data.uri} allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" allowFullScreen></iframe></IframeContainer>
          } else return <a href={node.data.uri} target="_blank" rel="noopener noreferrer">{node.content[0].value}</a>
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const asset = block[embeddedAssetCount];
          embeddedAssetCount++;
          if (asset?.contentType?.startsWith('image/')) {
            return (<figure><img className={'inline-image'} src={asset.url} alt={asset.title}/><figcaption>{asset.description}</figcaption></figure>);
          }
        },
      }
    }
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: ['null', 'null', '780px']
      }}
    >
      <Text
        as="h1"
        variant="pageHeading"
        sx={{
          paddingTop: ['84px', '160px'],
          fontSize: ['null', '42px']
        }}
      >
        {props.title}
      </Text>
      <Flex>
        {props.location ? 
          <Flex
            sx={{
              alignItems: 'center',
              marginTop: '10px',
              marginRight: '24px'
            }}
          >
            <Image
              src={locationIcon}
            />
            <Text
              variant="cardDetails"
              sx={{
                marginLeft: '12px',
                fontSize: ['null', '18px']
              }}
            >
              {props.location}
            </Text>
          </Flex>
        : ''}
        {!isAboutPage ?
          <Flex
            sx={{
              alignItems: 'center',
              marginTop: '10px'
            }}
          >
            <Image
              src={dateIcon}
            />
            <Text
              variant="cardDetails"
              sx={{
                marginLeft: '12px',
                fontSize: ['null', '18px']
              }}
            >
              {formatDate(props.date, props.currentLocale)}
            </Text>
          </Flex>
        : ''}
      </Flex>
      <Text
        variant="intro"
        sx={{
          ...articleBody,
          'p': {
            margin: '18px 0 0 0'
          }
        }}
      >
      {documentToReactComponents(props.intro)}
      </Text>
      <Text
        sx={articleBody}
      >
      {documentToReactComponents(props.body.json, options(props.body.links?.assets?.block))}
      </Text>
    </Flex>
  )
}

NewsArticle.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  intro: PropTypes.object,
  body: PropTypes.object,
}

export default NewsArticle