/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import { useContext, useState, useEffect } from "react"
import { useQuery, gql } from '@apollo/client'
import { ThemeProvider } from 'theme-ui'
import { theme } from '../styles/theme'
import '../styles/fonts.css'

import { SiteAccessContext } from '../components/SiteAccessContext'

import Layout from "../components/layout"
import Container from "../components/container"
import Heading from '../components/heading'
import NewsArticleCard from "../components/news/news-article-card"
import Catchphrase from "../components/catchphrase"
import ShowMore from "../components/ui/show-more"
import LearnCta from "../components/news/learn-cta"
import ProjectsCta from "../components/news/projects-cta"
import translate from "../config/translations.js"

const IndexPage = ({ currentLocale, setCurrentLocale }) => {
  const GET_PAGES = gql`
    query GetPages($currentSiteAccess: String!) {
      pageCollection(where: {
        site: {
          title: $currentSiteAccess
        }
        title: "News"
      }) {
        items {
          title
          componentsCollection {
            items {
              ... on ComponentsCategory {
                sys {
                  id
                }
                name
              }
            }
          }
        }
      }
    }
  `

  const GET_ARTICLES = gql`
    query GetArticles($offset: Int!, $limit: Int!, $newsCategoryId: String!, $currentLocale: String!) {
      articleCollection(skip: $offset, limit: $limit, locale: $currentLocale, where: {
        category: {
          sys: {
            id: $newsCategoryId
          }
        }
      }) {
        total
        items {
          sys {
            id
            publishedAt
          }
          title
          location
          coverImage {
            url
          }
        }
      }
    }
  `

  const currentSiteAccess = useContext(SiteAccessContext)

  const [newsCategoryId, setNewsCategoryId] = useState("")
  const [articles, setArticles] = useState([])
  const [limit, setLimit] = useState(7)
  const [currentLength, setCurrentLength] = useState(articles ? articles.length : 0)
  const [limitReached, setLimitReached] = useState(true)

  const { data: pagesResult, loading: pagesQueryLoading, error: articlesQueryError } = useQuery(GET_PAGES, {
    variables: { currentSiteAccess },
    fetchPolicy: 'cache-and-network'
  })
  const { data: articlesResult, loading: articlesQueryLoading, error: pagesQueryError, fetchMore } = useQuery(GET_ARTICLES, {
    variables: { offset: 0, limit, newsCategoryId, currentLocale },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (pagesQueryLoading === false && pagesResult && pagesResult.pageCollection.items[0].componentsCollection.items[0]) {
      setNewsCategoryId(pagesResult.pageCollection.items[0].componentsCollection.items[0].sys.id)
    }
    if (articlesQueryLoading === false && articlesResult) {
      setArticles(articlesResult.articleCollection.items[0] ? articlesResult.articleCollection.items : [])
      setCurrentLength(articlesResult.articleCollection.items.length)
      setLimitReached(articlesResult.articleCollection.items.length < limit
        || articlesResult.articleCollection.items.length === articlesResult.articleCollection.total
        ? true : false)
    }
    if (articlesQueryError) console.error(articlesQueryError)
    if (pagesQueryError) console.error(pagesQueryError)
  }, [pagesResult, pagesQueryLoading, articlesResult, articlesQueryLoading, articlesQueryError, pagesQueryError, limit])

  const handleShowMore = () => {
    fetchMore({
      variables: {
        offset: currentLength,
        limit: 4
      }
    }).then(fetchMoreResult => {
      let additionalLength = fetchMoreResult.data.articleCollection.items.length
      setLimit(currentLength + additionalLength)
      additionalLength < 4 ? setLimitReached(true) : setLimitReached(false)
    })
  }

  return (
    <ThemeProvider theme={theme[currentSiteAccess]}>
      <Layout
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
      >
        <Container>
          <Catchphrase
            catchphrase={ translate('catchphrase') }
            readMore={ translate('readMore') }
          />
          <Heading pageTitle={ translate('latestNews') } />
          {articles ? articles.map((article, i) => 
            <NewsArticleCard
              id={i}
              key={article.sys.id}
              contentId={article.sys.id}
              title={article.title}
              location={article.location}
              date={article.sys.publishedAt}
              image={article.coverImage ? article.coverImage.url : ""}
              currentLocale={currentLocale}
            />
          ) : ``}
          {!limitReached ?
            <ShowMore
              onClick={handleShowMore}
              loading={articlesQueryLoading}
            />
          : ''}
          <Flex
            sx={{
              display: ['none', 'flex'],
              justifyContent: 'center',
              marginTop: ['null', '24px', '46px']
            }}
          >
            <ProjectsCta
              title={ translate('projectCtaTitle') }
              description={ translate('projectCtaDescription') }
            />
            <LearnCta
              title={ translate('learnCtaTitle') }
              description={ translate('learnCtaDescription') }
            />
          </Flex>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default IndexPage
